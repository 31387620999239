<template>
  <div class="space-y-4">
    <asom-card>
      <asom-alert
        class="col-span-2"
        v-if="error"
        variant="error"
        :error-message="error"
      />
      <div class="py-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Cash Order Information
        </h3>
      </div>
      <div v-if="!isLoading" class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl class="sm:divide-y sm:divide-gray-200">
          <div class="py-4 sm:py-5 grid sm:grid-cols-1 md:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Reference No
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ get(cashOrder, "referenceNo", "") }}
            </dd>
          </div>
          <div class="py-4 sm:py-5 grid sm:grid-cols-1 md:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Cash Order Status
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ addSpacing(get(cashOrder, "cashOrderStatus", "")) }}
            </dd>
          </div>
          <div class="py-4 sm:py-5 grid sm:grid-cols-1 md:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Cash Order Type
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ addSpacing(get(cashOrder, "cashOrderType", "")) }}
            </dd>
          </div>
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-1 md:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Order Amount
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ formatCurrency(get(cashOrder, "expectedAmount", 0)) }}
            </dd>
          </div>
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-1 md:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Received Amount
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ receivedAmount }}
            </dd>
          </div>
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-1 md:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Is Urgent
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ getIsUrgent(get(cashOrder, "isUrgent", "")) }}
            </dd>
          </div>
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-1 md:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Remarks
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ get(cashOrder, "remarks", "") }}
            </dd>
          </div>
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-1 md:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Requested Delivery Date
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{
                displayUtcDate(get(cashOrder, "requestedDeliveryDate", moment()))
              }}
            </dd>
          </div>
        </dl>
      </div>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
      <div class="col-span-2 flex justify-end space-x-4">
        <asom-button text="Back" variant="secondary" @click="$router.push({ name: 'Cash Order Main Page' })" />
      </div>
    </asom-card>
  </div>
</template>

<script>
import get from "lodash.get";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { getCashOrderDetails } from "../../../services/cashManagement.service";
import { formatCurrency } from "../../../helpers/numbers";
import moment from 'moment';

export default {
  name: "ViewCashOrder",
  data() {
    return {
      cashOrder: {},
      isLoading: true,
      error: null,
    };
  },
  mounted() {
    this.getPSMRecordDetails();
  },
  computed: {
    receivedAmount() {
      const amount = get(this.cashOrder, "receivedAmount", 0);
      return amount === 0 ? "-" : formatCurrency(amount);
    },
  },
  methods: {
    get,
    formatCurrency,
    moment,
    getIsUrgent(isUrgent) {
      if (isUrgent) return "Yes";
      else return "No";
    },
    displayUtcDate,
    addSpacing(string) {
      return string.replace(/([A-Z])/g, " $1").trim();
    },
    async getPSMRecordDetails() {
      this.isLoading = true;
      const result = await getCashOrderDetails(
        get(this.$route.params, "cashOrderId")
      );
      if (result.success) {
        this.isLoading = false;
        this.cashOrder = get(result.payload, "data");
      } else {
        this.isLoading = false;
        this.error = result.payload;
      }
    },
  },
};
</script>
